import AliceCarousel from "react-alice-carousel";
import "./homeCarousel.css";
import styles from "./_homeCarousel.module.scss";
import img1 from "../../../Images/flute_background_1.jpeg";
import img2 from "../../../Images/flute_background_2.jpeg";
import img3 from "../../../Images/flute_background_3.jpeg";
import img4 from "../../../Images/flute_background_4.jpeg";
import img5 from "../../../Images/flute_background_5.jpeg";

const mainCarouselData = [
  {
    image: img1,
    path: "flute_background",
  },
  {
    image: img2,
    path: "flute_background",
  },
  {
    image: img3,
    path: "flute_background",
  },
  {
    image: img4,
    path: "flute_background",
  },
  {
    image: img5,
    path: "flute_background",
  },
];

const HomeCarousel = () => {
  const items = mainCarouselData.map((item, index) => (
    <img
      key={index}
      className={styles.homeCarousel}
      role="presentation"
      src={item.image}
      alt={item.path}
    />
  ));
  return (
    <AliceCarousel
      items={items}
      disableButtonsControls
      autoPlay
      autoPlayInterval={2000}
      infinite
    />
  );
};

export default HomeCarousel;
